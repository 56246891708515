#file-upload {
  display: none;
}

.button_blue {
  cursor: pointer;
  display: inline-block;
}

#settings-delete-asset,
#settings-delete-map,
#settings-delete-scenario,
#settings-edit-asset,
#settings-edit-map,
#settings-edit-scenario {
  .builder05contnet_popup {
    min-width: 423px;
    text-align: left;
    input {
      width: 100%;
      font-size: 20px;
      line-height: normal;
      padding: 3px;
      margin-bottom: 30px;
    }
    div {
      display: flex;
      justify-content: space-around;

    }
    .error {
      color: red;
      margin-bottom: 1.5vw;
    }
  }
  .button_blue,.button_gray {
    margin:0;
    span {
      padding-left: 33px;
      padding-right: 33px;
    }
    &.disabled {
      span {
        color: gray;
      }
    }
  }
}

.settings-add-map, #settings-add-asset {
  .upload {
    h4 {
      padding-bottom: 5px;
    }
    h5 {
      color: #1f79c4;
      margin-bottom: 20px;
    }
    a {
      margin-top: 20px;
    }
  }
}

.settings19_pagetable {
  tbody {
    display: block;
    //height: calc(100vh - 250px);
    overflow: auto;
  }
  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  thead {
    //width: calc( 100% - 1em )
  }
  &.file-list {
    td:last-child {
      padding-left: 1rem;
    }
  }
}

@mixin button_blueborder {
  display: inline-block;
  > span {
    display: inline-block;
  }
}

.settings19_pagecontent {
  &.libraries {
    .button_blueborder {
      @include button_blueborder;
    }
    .settings19_pagetable {
      tr {
        cursor: pointer;
        > td:first-child {
          padding-left: 2rem;
          position: relative;
          &::before {
            content: '';
            width: 1.2rem;
            height: 1.2rem;
            position: absolute;
            top: 1rem;
            left: 0.5rem;
            background: url(/images/room_icon1.png) no-repeat left center;
            transform: rotate(-90deg);
          }
        }
      }
      .selected {
        background-color: #cfdae3;
        > td:first-child {
          &::before {
            transform: rotate(0);
          }
        }
        &.asset-selected {
          background-color: #e7e7e7;
        }
      }
      .asset {
        background-color: #dedede;
        > td:first-child {
          padding-left: 3rem;
          &::before {
            width: 2rem;
            height: 2rem;
            top: 0.6rem;
            background: url(/images/object_icon.png) no-repeat left center;
            background-size: contain;
            transform: rotate(0);
          }
        }
        &.selected {
          background-color: #c7d2db;
        }
      }
    }
  }
}

.settings18_pagebox1 {
  margin-bottom: 2rem;
  &.flex-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  a.button_blueborder {
    width: auto;
    margin-bottom: 5px;
  }
  h6 {
    color: black;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.context-sidebar {
  background-color: #999999;
  .button_blueborder {
    @include button_blueborder;
  }
  > div {
    width: 25vw;
  }
}

.rightsidebar_manageusers {
  height: 1; /* Have to set a height for flex-grow scroll to work. */ 
  margin-top: 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  background: #999999;
  min-height: calc(100vh - 70px);
  .tabs {
    margin-top: 0;
  }
  .settings18_pagebox1 {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .error {
    color: red;
    margin-top: 1rem;
  }
  
}

.manage15_pagetable {
  .active {
    background-color: #00000026;
  }
  tr:hover{
    background-color: rgba(#1E79C4,0.2)
  }
}
